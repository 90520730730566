.image-gallery-slide img {
  height: 60vh;
  object-fit: fill;
  overflow: hidden;
  object-position: center center;
}

@media only screen and (max-width: 768px) {
  .image-gallery-slide img {
    height: 25vh;
  }
}
